@import "../../style/variables.module.scss";

.button{
    height:64px;
    width:100%;
    color:$white-color;
    background-color: $red-color-1;
    font-size: 16px;
    border-radius: 10px;
    border:0;
    cursor: pointer;
}

button:disabled{
    cursor:default;
    position: relative;
}

.overlay{
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0.5;
}

.inverted{
    width:100%;
    color:$red-color-1;
    background-color: transparent;
    font-size: 16px;
    border-radius: 12px;
    border: 1px solid;
    cursor: pointer;
}