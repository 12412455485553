@import '../../style/variables.module.scss';
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 20;
    
  }

  .displayBlock {
    display: block;
  }
  
  .displayNone {
    display: none;
  }
  
  .modalMain {
    position:fixed;
    //background: white;
    //width: 80%;
    width: max-content;
    top:50%;
    left:50%;
    z-index: 21;
    transform: translate(-50%,-50%);
    border-radius: 12px;
    padding: 39px 32px 32px 32px;
  }

  .title{
      font-size: 22px;
      color: $blue-color;
      font-weight: 600;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .childrenContainer{
      margin-bottom: 15px;
  }

  .close{
    font-size: 25px;
    color: $grey-color4;
    cursor: pointer;
    font-weight: normal;
}

@media screen and (max-width: 800px) {
  .title{
    font-size: 20px;
  }
  .childrenContainer{
    font-size: 13px;
  }
  .modalMain{
    width: min-content;
  }
}