.header{
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  color: #293F62;
  margin-bottom: 32px;
}

.table{
  display: grid;
  grid-template-columns: 4fr 1fr 1fr minmax(50px, 80px);
  :nth-child(4){
    text-align: right;
  }
  &__span{
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;  
    color: #091D60;
  }
}

.tableMobile{
  display: flex;
  column-gap: 12px;
  align-items: center;
  img{
    display: block;
    width: 72px;
    height: 72px;
  }
}

.containerMobile{
  width: 100%;
}

.iconSectionMobile{
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr minmax(50px, 80px);;
  :nth-child(3){
    justify-content: flex-end;
  }
  &__container{
    display: flex;
    align-items: center;
    column-gap: 6px;
  }
  &__icon{
    width: 14px !important;
    height: 14px !important;
    display: block;
  }
}

.spanMobile{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #293F62;
}

.container{
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.tableHeader{
  margin-bottom: -3px;
  span{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #293F62;
  }
}
.imageSection{
  display: flex;
  column-gap: 16px;
  align-items: center;
  img{
    max-width: 42px;
    max-height: 42px;
  }
  &__data{
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    :first-child{
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      color: #091D60;
    }
    :last-child{
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #293F62;
      opacity: 0.7;
    }
  }
}