
@import "../../style/variables.module.scss";
.round {
    position: relative;
  }
  
  .round label {
    //background-color: $white-color;
    border: 1.5px solid;
    border-radius: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  .round label:after {
    border: 1.5px solid $white-color;
    border-top: none;
    border-right: none;
    content: "";
    // height: 4px;
    height: 4px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 10px;
  }
  
  .round input[type=checkbox] {
    visibility: hidden;
    margin:0;
    height:0;
  }
  
  .round input[type=checkbox]:checked + label {
    background-color: $red-color-1 !important;
    border-color: $red-color-1 !important;
  }
  
  .round input[type=checkbox]:checked + label:after {
    opacity: 1;
  }

  .container {
   // margin: 0 auto;
  }
 
  