@import '../../style/variables.module.scss';

.sidebar_container {
  max-width: 278px;
  width: 100%;
  min-height: calc(100vh + 200px);
  padding: 24px;
  border-right: 1px solid;
  position: relative;
  box-sizing: border-box;
}

.logo {
  max-width: 136px;
  margin-bottom: 34px;
}

.sidebar_item {
  max-width: 214px;
  width: 100%;
  height: 64px;
  padding: 24px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    //color: $blue-color;
    margin-left:16px;
    margin-bottom: 0;
  }
}

.disabled{
  max-width: 214px;
  width: 100%;
  height: 64px;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  margin-left: 8px;
  margin-right: 8px;

  p {
    margin-left:16px;
  }
}

.disabled:hover{
  color:$black-color3;
}

.sidebar_item_icon {
  width: 22px;
  height: 22px;
}

.sidebar_active_item {
  //background: linear-gradient(36.46deg, $pink-color 0%, $pink-color-light 100%);
  background-color: $red-color-1;
  p {
    color: $white-color;
  }
}

.category_label {
  margin: 44px 0 12px 0;
  padding-left: 19px;
  color: $grey-color4;
  font-size: 14px;
}

.sidebar_notification {
  width: 6px;
  margin-left: 2px;
}


.loggedUser{
  align-self: flex-end;
  display: flex;
  margin: 27px 0px 64.6px 0;
  align-items: center;
  justify-content: space-between;
}

.iconContainer{
  display: flex;
  position: relative;
  margin-right: 16.4px;
}

.icon{
  width: 43.6px;
  height: 43.6px;
}

.admin{
  width: 16px;
  height: 16px;
  position: absolute;
  left:30px;
  top:-3px;
}

.name{
  //color: $blue-color;
  font-size: 14px;
}
.role{
  font-size: 13px;
  margin-top: 7px;
  //color: $grey-color9;
  text-transform: capitalize;
}

.arrowBtn{
  width: 26.2px;
  height: 26.2px;
  margin-left: 21px;
  position: relative;
}

.menu{
  position: absolute;
  z-index: 11;
  width: 150px;
  top:37px;
  right:7px;
  border: solid 1px $grey-color11;
  border-radius: 16px;
  padding: 13px 16px 12px;
  color: $grey-color9;
  font-size: 16px;
  box-sizing: border-box;
}
.menu:hover{
  cursor: pointer;
}