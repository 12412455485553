@import '../../style/variables.module.scss';

.circle{
    width: 5px;
    height: 5px;
    border: solid 1.3px;
    border-radius: 50%;
    margin: 0 0 4px;
}

.circle:last-child{
    margin: 0 !important;
}
.container:hover{
    cursor: pointer;
}