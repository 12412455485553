.social__container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    button:first-child {
        background: #4285F4;
    }
}
.social__button {
    background: white;
    border: 1px solid grey;
    height: 40px;
    width: 200px;
    cursor: pointer;
}
.social {
    position: relative;
    color: #fff;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    img {
        position: absolute;
        left: -5px;
        top: -11px !important;
        width: 34px !important;
        height: 35.5px;
        background: white;
    }
    span {
        margin-left: 24px;
    }
}