.header {
    width: 100%;
    height: 120px;
    background-color: #0948B3;
    display: flex;
    justify-content: center;
    .header__content {
      width: 75%;
      // display: flex;
      color: #ffffff;
      padding-top: 20px;
      p {
        margin-left: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 500;
      }
      h2 {
        color: white;
      }
    }
  }

  section p {
    margin: 0;
    padding: 0;
  }

.privacy-notice {
    line-height: 1.6;
    color: #000000;
    font-weight: 500;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    
}

.privacy-notice h1,
.privacy-notice h2,
.privacy-notice h3 {
    color: #0056b3;
    font-size: 2rem;
}

.privacy-notice a {
    color: #0056b3;
    text-decoration: none;
}

.privacy-notice a:hover {
    text-decoration: underline;
}

.table-responsive{
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* for smooth scrolling on touch devices */
    margin-bottom: 20px;
}


.privacy-notice table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    overflow-x: scroll;

}

.privacy-notice table th,
.privacy-notice table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.privacy-notice table th {
    background-color: #f2f2f2;
}

.privacy-notice ul {
    list-style-type: disc;
    margin-left: 20px;
    padding-bottom: 20px;
}

.privacy-notice p {
    margin-bottom: 16px;
}

.privacy-notice strong {
    font-weight: bold;
}

@media (max-width: 768px) {
    .privacy-policy {
        padding: 10px;
    }
    .privacy-policy h1 {
        font-size: 1.2em; /* smaller size for mobile */
    }
    .privacy-policy h2 {
        font-size: 1em;
    }
    .privacy-policy p, 
    .privacy-policy ul, 
    .privacy-policy a {
        font-size: 0.9em;
    }
    .privacy-policy table, 
    .privacy-policy th, 
    .privacy-policy td {
        font-size: 0.7em;
    }
}
