@import '../../style/variables.module.scss';

.container{
  position: relative;
}
.dropDown{
  position: absolute;
  //background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: solid 1px;
  border-radius: 16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-sizing: border-box;
  z-index: 30;
  :nth-child(n){
    //color: $grey-color9;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: solid 1px;
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
    // &:hover{
    //   background-color: $grey-color11;
    // }
  }
  :last-child{
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: none;
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
    &:hover{
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      //background-color: $grey-color11;
    }
  }
}

.info{
  position: relative;
  //display: flex;
}

.infoText{
  visibility: hidden;
  box-shadow: 1px 7px 15px $lightGrey-color-3;
  min-width: 200px;
  text-align: left;
  padding: 7px;
  border-radius: 6px;
  font-size: 12px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 45px;
  z-index: 100;
}

.info:hover .infoText {
  visibility: visible;
}

.closeButton{
  position: absolute;
  z-index: 1000;
  top: 65px;
  left: 1px;
  cursor: pointer;
  padding: 5px;
  &:hover{
    background-color: $grey-color11;
  }
}
.button{
  column-gap: 10px !important;
}
.buttonOpen{
  border-bottom: none;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.datePicker{
  z-index: 30;
  display: flex;
  position: absolute;
  right: 0;
  top: 120%;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
  &__calendar{
    position: relative;
    display: flex;
    justify-content: center;
    span{
      position: absolute;
      z-index: 50;
      font-size: 16px;
    }
  }
}