@import '../../style/variables.module.scss';

.icon{
    width: 104px;
    height: 104px;
    border-radius: 50%;
    margin-top: 32px;
}

.buttonContainer{
    display: flex;
    margin-top: 20px;
}