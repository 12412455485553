@import '../../style/variables.module.scss';

.outlinedIcon{
    width: 22px;
    height: 23px;
    margin-left: 8px;
    img{
        width: 12px;
        height: 12px;
    }
    border: 1px solid;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
}