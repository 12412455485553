.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.crop_container {
  position: relative;
  width: 460px;
  height: 488px;
}
.image {
  max-width: none;
  max-height: none;
}
.cropContainer {
  border-radius: 8px;
}
.controls {
  position: relative;
  bottom: 92px;
  left: 408px;
  display: flex;
}
.minus {
  width: 32px;
  height: 32px;
  background-color: transparent;
  position: absolute;
  display: flex;
  border: 1.2px solid #ffffff;
  top: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p{
    font-weight: bold;
    color: white;
  }
}
.minus:hover, .plus:hover{
  background-color: white;
  p{
    color: black;
  }
}
.plus {
  width: 32px;
  height: 32px;
  background-color: transparent;
  position: absolute;
  display: flex;
  border: 1.2px solid #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p{
    font-weight: bold;
    color: white;
  }
}
.buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}
.cancel_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: static;
  width: 99px;
  height: 48px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.cancel_button:hover{
  background-color: #fafafa;
}
.save_button:hover{
  background-color: #d84035;
}
.save_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 48px;
  top: 0px;
  background: #ef3124;
  border-radius: 8px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  cursor: pointer;
}
