// fonts:
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-ExtraBold.ttf');
    font-weight: 900;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Light.ttf');
    font-weight: 400;
}

// font sizes
$font-size10: 10px;
$font-size11: 11px;
$font-size12: 12px;
$font-size13: 13px;
$font-size14: 14px;
$font-size15: 15px;
$font-size16: 16px;
$font-size17: 17px;
$font-size18: 18px;
$font-size20: 20px;
$font-size22: 22px;
$font-size24: 24px;
$font-size28: 28px;
$font-size30: 30px;
$font-size32: 32px;
$font-size34: 34px;
$font-size38: 38px;
$font-size40: 40px;
$font-size44: 44px;
$font-size45: 45px;
$font-size48: 48px;
$font-size54: 54px;
$font-size60: 60px;


//colors
$black-color: #000;
$white-color: #fff;
$blue-color: #091d60;
$black-color3: rgba(0,0,0,0.3);
$black-color5: rgba(0,0,0,0.5);
$grey-color: #cccccc4f;
$grey-color2: #BBBFD2;
$grey-color3: #101111;
$grey-color4: #646879;
$grey-color5: #EAF1F8;
$grey-color6: rgba(228, 228, 228, 0.4);
$grey-color7: #808191;
$grey-color8: #ccc;
$grey-color9: #8C92B2;
$grey-color10: #eaf1f8;
$grey-color11: #E6E8F1;
$grey-color12: #707070;
$grey-color13: #d3d6de;
$grey-color14: #f2f3f5;
$grey-color15: #eeeff4;
$grey-color16: #9c9c9c;
$grey-color17: #949fb1;
$green-color13: #a2aab2;
$grey-textfield-color: #ebedf1;
$grey-textfield-color-2: #293f62;
$green-color: #00DC4D;
$green-color2: #34e255;
$lightBlue-color: #918ef7;
$lightBlue-color2: #69BBF8;
$lightBlue-color3: #5f75ee;
$pink-color: #FF5D5F;
$pink-color-light: #FF7356;
$pink-color-2: #ff5a5e;
$blue-color2: #77C4FD;
$blue-color2-light: #A5D8FF;
$lightGrey-color: #adb2d0;
$lightGrey-color-2: #e4e4e4;
$lightGrey-color-3: #e3e6ec;
$lightGrey-color-4: #e4eaf0;
$red-color-1: #ec1e24;
$faded-red-1: #ebcecf;

//platform colors
$youtube-color: #FF0000;
$youtube-color-light: #FF5D60;
$followers-color: #AFADFA;
$earnings-color: #69BBF8;
$spotify-color: #00DC4D;
$snapchat-color: #FFFC00;
$facebook-color: #4267B2;
$tiktok-color: #000000;

//instagram has an gradient
$instagram-background-gradient: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
