@import '../../style/variables.module.scss';
.wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 29px;
    padding: 21px 22px 87px 19px;
    width: calc(100vw - 285px);
    box-sizing: border-box;
    position: relative;
}

.content{
    margin-top: 36px;
    display: flex;
    column-gap: 20px;
    //flex-direction: row;
}

.leftSideContent{
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    // flex-direction: column;
    row-gap: 20px;
    color: black;
}
.paymentContent{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.row{
    display: flex;
    align-items: baseline;
    column-gap: 20px;
}
.fontRow{
    font-size: 16px;
    font-weight: 500; 
}
.fontPayment{
    font-size: 20px;
    font-weight: 500;
}
.paymentHeader{
    font-size: 16px;
    font-weight: 500;
}
.paymentDetailLeft{
    min-width: 200px;
}
.paymentDetailRight{
    min-width: 100px;
}
.amount {
    font-size: 60px;
}
.amountDescription {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
}
.submitLoader{
    position: absolute;
    width: 99%;
    height: 100%;
    div{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
.dateWrapper {
    display: flex;
    column-gap: 10px;
    position: absolute;
    right: 0px;
    top: -45px;
}
.dateRangeContainer {
    display: flex;
    column-gap: 10px;
    justify-content: center;
    button {
        border: none;
        background: red;
        border-radius: 5px;
        color: white;
        min-height: 35px;
    }
}
.chart {
    width: 60%;
    position: relative;
    min-height: 200px;
}
.dateRangeParent {
    display: flex;
}
.transaction{
    margin-top: 28px;
}
.loaderWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

@media screen and (max-width: 1200px) {
    .content{
        flex-direction: column;
    }
}

@media screen and (max-width: 890px) {
    .wrapper{
        width: 100vw;
        padding: 0 16px 44px 16px;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }
    .paymentDetailLeft{
        min-width: 180px;
    }
    .leftSideContent {
        flex-direction: column;
    }
    .chart {
        width: 100%;
    }
    .dateWrapper {
        position: relative;
        flex-direction: column;
        row-gap: 20px;
    }
    .dateRangeParent {
        transform: scale(0.8);
    }
}
