.container{
  display: flex;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  column-gap: 10px;
  &__last{
    margin-left: auto;
  }
}
.img{
  width: 32px;
  height: 32px;
  display: block;
}

.textContainer{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  :first-child{
    font-size: 16px;
    font-weight: 600;
  }
  :last-child{
    opacity: 0.8;
    font-size: 13px;
  }

}
.tooltip{
  background-color: white;
  padding: 24px;
  border: 1px solid lightgray;
  border-radius: 10px;
  //white-space: nowrap;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: start;
  row-gap: 24px;
  &__row{
    cursor: pointer;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: start;
    img{
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}

.modal{
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__main{
    display: flex;
    column-gap: 20px;
  }
  &__icon{
    display: block;
    width: 40px;
    height: 40px;
  }
  &__spanIcon{
    vertical-align: middle;
    img{
      height: 16px;
      width: 16px;
    }
  }
  &__span{
    display: inline;
  }
  &__bolded{
    font-weight: 600;
    display: inline;
  }
  &__text{
    max-width: 415px;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
  &__buttons{
    display: flex;
    justify-content: start;
    column-gap: 16px;
    &__button{
      padding: 10px 15px 10px 15px;
      border-radius: 10px;
      border: 1px solid lightgray;
      background: none;
      color: #293F62;
      cursor: pointer;
    }
  }
}

.warning{
  color: red;
}