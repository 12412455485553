.main{
    display: flex;
    flex-direction: column;
    row-gap: 29px;
    padding: 21px 25px 60px 19px;
  }
  
  .progressSection{
    display: flex;
    column-gap: 10px;
    align-items: center;
    //grid-template-columns: 1fr 15fr 1fr 15fr 1fr 15fr 1fr 15fr 1fr 15fr 1fr;
    overflow-x: scroll;
    justify-content: center;
    &__icon{
      cursor: pointer;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: block;
      box-sizing: border-box;
      padding: 5px;
    }
    &__line{
     // width: 50vw; 
      height: auto;
      display: block;
      max-width: 200px;
    }
  }
  
  .progressSection::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .progressSection {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .selected{
    background-color: rgb(255, 0, 0) !important;
    filter: none !important;
    //border: none !important;
    border: 2px solid red !important;
    border-radius: 50%;
    img{
      width: 48px;
      height: 48px;
      display: block;
      filter: brightness(100) saturate(100%) !important;
      border: none !important;
    }
  
  }
  
  .filter{
    &__container{
      filter: none;
      border: 2px solid #E3E6EC;
      border-radius: 50%;
    }
    display: block;
    border: none !important;
    filter: brightness(0) saturate(100%) invert(9%) sepia(45%) saturate(5174%) hue-rotate(225deg) brightness(90%) contrast(103%);
  }
  