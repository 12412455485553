.container{
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.header{
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  // color: #091D60;
}
.main{
  display: flex;
  column-gap: 40px;
  row-gap: 40px;
  flex-wrap: wrap;
}
.left_section{
  flex: 1;
  background: linear-gradient(0deg, #000000, #000000);
  border-radius: 22px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  height: fit-content;
  @media screen and (max-width: 500px){
    width: 100%;
  }
}

.right_section{
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-width: 300px;
  &__input{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    span{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      // color: #293F62;
      opacity: 0.7;
      height: 18px;
    }
  }
  :nth-last-child(1){
    flex-grow: 1;
  }
  &__buttons{
    display: flex;
    column-gap: 24px;
    flex-wrap: wrap;
    row-gap: 24px;
    align-items: flex-end;
    :first-child{
      flex: 1;
      cursor: pointer;
      border: 1px solid #091D60;
      box-sizing: border-box;
      border-radius: 16px;
      background: none;
      padding: 22px 22px 22px 22px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #091D60;
    }
    :last-child{
      flex: 1;
      cursor: pointer;
      border: none;
      background: #EC1E24;
      border-radius: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      padding: 22px 22px 22px 22px;
      &:disabled{
        justify-content: center;
        opacity: 0.5;
      }
    }
  }
}

.uploadSegment{
  position: relative;
  display: flex;
  column-gap: 24px;
  align-items: center;
  padding: 71px 100px 71px 100px;
  border: 1px dashed rgba(250, 250, 250, 0.4);
  border-radius: 12px;
  cursor: pointer;
  img{
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 12px;
  }
  &__text_container{
    max-width: 170px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    :first-child{
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #FAFAFA;
      opacity: 0.8;
    }
    :last-child{
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #C4C4C4;
      opacity: 0.7;
    }
    audio{
      max-width: 220px;
      @media screen and (max-width: 500px){
        width: 180px;
      }
    }
  }
}
.uploadArtWork{
  width: auto !important;
  height: auto !important;
  &:hover{
    transform: scale(4);
    opacity: 1;
  }
}
.timepicker{
  border: 1px solid rgb(211, 214, 222);
  font-size: 15px;
  width: 100%;
  margin-top: 8px;
  border-radius: 8px;
  height: 56px;
}
.loader{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  div{
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
}
.radioButtons{
  display: flex;
}