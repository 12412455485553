@import '../../style/variables.module.scss';

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    display: none;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}

[type="radio"]:checked + label{
    color: $red-color-1;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 1.5px;
    top: -0.5px;
    width: 19px;
    height: 19px;
    border: 1px solid $red-color-1;
    border-radius: 100%;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: $red-color-1;
    position: absolute;
    top: 2px;
    left: 4px;
    border-radius: 100%;
    // -webkit-transition: all 0.2s ease;
    // transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    // -webkit-transform: scale(0);
    // transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    // -webkit-transform: scale(1);
    // transform: scale(1);
}

p{
    margin-left: 33px;
}
p:first-child{
    margin-left: 0;
}
