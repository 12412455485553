.container {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #070706;
    column-gap: 20px;
    justify-content: end;
    z-index: 9;
    padding: 10px;
    div {
        color: #fff;
        cursor: pointer;
    }
}
@media screen and (max-width: 500px) {
    .container {
        justify-content: space-between;
    }
}