.header{
  display: flex;
  justify-content: space-between;
  &__right{
    flex-direction: row;
  }
  &__left{
    flex-direction: row-reverse;
  }
}
.icon{
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.iconSpan {
  max-height: 26px;
  background: #a69a9a66;
  border-radius: 5px;
};