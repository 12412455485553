@import '../../style/variables.module.scss';

.container{
    //max-width: 350px;
    min-width: 200px;
    padding: 47px 0px 47px 0px;
    border-radius: 16px;
    border: solid 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.subtitle{
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1.33;
}

.total{
    font-size: 40px;
    font-weight: 600;
    line-height: 1.53;
    letter-spacing: -0.72px;
    margin-top: 6px;
}
.iconContainer{
    margin-top: 11px;
    margin-bottom: 11px;
    display: flex;
}

.arrow{
    width: 6.2px;
    height: 3.4px;
    margin-right: 8px;
}

.subtext{
    color: $grey-color7;
    font-size: 12px;
}
.percentage{
    font-size: 12px;
    color: $green-color2;
    display:flex;
    align-items: center;
}

.lowerSection{
    color: $green-color13;
    margin-top: 13px;
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 220px;
}