@import '../../style/variables.module.scss';
.container{
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 20px 40px 12px 47.7px;
    padding-right: 32px;
    padding-left: 32px;
    box-sizing: border-box;
}

.containerOnboarding{
    height: 110px;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    padding-right: 32px;
    box-sizing: border-box;
}

.buttonChildrenContainer{
    display: flex; 
    align-items: center;
    font-weight: bold;
}
.buttonImage{
    width: 22px; 
    height: 22px;
    margin-right: 12px;
}

.settings{
    font-size: 24px;
    font-weight: bold;
}

.logo{
   // background-color: turquoise;
    display: flex;
   align-items: center;
}

.logoDivider{
    //border: 1px solid $grey-color9;
    opacity: 0.16;
    width: 1px;
    height:110px;
    margin-left: 110px;
    background-color: $grey-color9;
}
.logoImg{
    height: 52px;
    width: 136px;
}
.welcome{
    font-size: 34px;
    font-weight: 600;
    color: $blue-color;
    flex-wrap: nowrap;
}

.upperText{
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 17px;
}

.lowerText{
    font-size: 18px;
}

.separator{
    height: 1px;
    opacity: 0.4;
    background-color: $lightGrey-color-2;
}

.searchContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    // max-width: 230px;
    //justify-content: space-between;
}

.avatar{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 16px;
}

@media only screen and (max-width: 890px) {
   .container{
       height: 81px;
       padding-right: 20px;
       padding-left: 20px;
   }
//    .searchContainer{
//     display: flex;
//     flex-direction: column;
// }


   .upperText{
       font-size: 20px;
       margin-bottom: 10px;
   }

   .lowerText{
       font-size: 14px;
   }
   .buttonImage{
       width:14px;
       height:14px;
       margin-right: 6px;
   }

  }