.container{
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image{
    width: 143px;
    height: 143px;
  }
  //position: relative;
}

.textContainer{
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  :first-child{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #091D60;
  }
  :last-child{
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    color: #293F62;
    max-width: 275px;
  }
}

.buttons{
  padding-top: 56px;
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;
  row-gap: 16px;
  align-items: flex-end;
  :first-child{
    max-width: 235px;
    flex: 1;
    cursor: pointer;
    border: 1px solid #091D60;
    box-sizing: border-box;
    border-radius: 16px;
    background: none;
    padding: 22px 22px 22px 22px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #091D60;
    min-width: 168px;
  }
  :last-child{
    max-width: 235px;
    flex: 1;
    cursor: pointer;
    border: none;
    background: #EC1E24;
    border-radius: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 22px 22px 22px 22px;
    min-width: 168px;
  }
}

.closeButton{
  width: 112px;
  height: 112px;
  padding: 0px;
  position: absolute;
  top: -85px;
  img{
    cursor: pointer;
    display: block;
    padding: 0px;
    object-fit: fill;
  }
}