@import '../../style/variables.module.scss';

.icon {
    height: 24px;
    width: 24px;
}

.badge{
    width: 24px;
    height: 24px;
    background-color: $red-color-1;
    color: $white-color;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -10px;
    top: -2px;
}