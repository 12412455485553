.modal {
    display: inline; 
    position: fixed; 
    z-index: 200; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_content {
    background-color: #fefefe;
    margin: auto;
    padding: 40px;
    border: 1px solid #888;
    width: 540px;
    height: 648px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
  }
  
//   .close {
//     color: #aaaaaa;
//     float: right;
//     font-size: 28px;
//     font-weight: bold;
//     position: absolute;
//     top: 14vh;
//     background-color: white;
//     width: 48px;
//     height: 48px;
//     border-radius: 50%;
//     display: flex;
//     justify-content: center;
// }
  
//   .close:hover,
//   .close:focus {
//     color: #000;
//     text-decoration: none;
//     cursor: pointer;
//   }