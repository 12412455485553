@import '../../style/variables.module.scss';

.tooltip{
    padding: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 5px;
    background-color: $white-color;
    box-shadow: 1px 7px 15px $lightGrey-color-3;
    white-space: nowrap;
}

.tooltipLabel{
    font-size: 17px;
    font-weight: 600;
    color:$blue-color;
}

.tooltipPct{
    //color: $pink-color-2;
    font-size: 12px;
    align-items: center;
    display: flex;
}

.downarrow{
    height: 10px;
    width: 10px;
    margin-right: 3px;
}