.container{
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  position: relative;
}
.header{
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: #091D60;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  span{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #293F62;
    opacity: 0.7;
  }
}
.main{
  padding-top: 20px;
  display: flex;
  column-gap: 40px;
  row-gap: 40px;
  flex-wrap: wrap;
}



.buttons{
  padding-top: 40px;
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  row-gap: 24px;
  align-items: flex-end;
  button{
    justify-content: center;
  }
  :first-child{
    max-width: 120px;
    flex: 1;
    cursor: pointer;
    border: 1px solid #091D60;
    box-sizing: border-box;
    border-radius: 16px;
    background: none;
    padding: 22px 22px 22px 22px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #091D60;
  }
  :last-child{
    max-width: 150px;
    flex: 1;
    cursor: pointer;
    border: none;
    background: #EC1E24;
    border-radius: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 22px 22px 22px 22px;
  }
}
.submitLoader{
  position: absolute;
  width: 100%;
  height: 100%;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
.modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 420px;
  &__main{
    display: flex;
    column-gap: 20px;
    flex-direction: column;
  }
  &__text{
    max-width: 415px;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
  &__buttons{
    display: flex;
    justify-content: end;
    column-gap: 16px;
    margin-top: 15px;
    &__button{
      padding: 10px 15px 10px 15px;
      border-radius: 10px;
      border: 1px solid lightgray;
      background: none;
      color: #293F62;
      cursor: pointer;
    }
    &__button__save{
      padding: 10px 15px 10px 15px;
      border-radius: 10px;
      border: 1px solid lightgray;
      background: #EC1E24;
      color: #fff;
      cursor: pointer;
    }
  }
}
