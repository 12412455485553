@import '../../style/variables.module.scss';

.container{
   // background-color: $white-color;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 1px 8px 15px $lightGrey-color-3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:visible;
}
.container:hover{
    cursor: pointer;
}