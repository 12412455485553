@import '../../style/variables.module.scss';

.mobile_nav {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  //margin-top: 28px;
 
}

.divider{
  background-color: $grey-color9;
  height: 1px;
  opacity: 0.16;
}

.networkContainer{
  display: flex;
  flex-direction: column;
}

.avatar_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        max-width: 20px;
        //margin-right: 45px;
    }
    .avatar_icon {
        max-width: 45px;
        margin-right: unset;
    }
}

.back_container {
  img {
    max-width: 12px;
    margin-right: 6px;
  }
  span {
    font-size: 16px;
  }
  display: flex;
  align-items: center;
}

.hamburger_container {
    display: flex;
    align-items: center;
    img {
      max-width: 16px;
    }
  }

.sidebar_container {
  max-width: 278px;
  width: 100%;
  //background-color: $white-color;
  min-height: 100vh;
  padding: 21px;
  overflow: scroll;
  position: absolute;
  z-index: 10;
}

.logo {
  max-width: 134px;
  margin-bottom: 34px;
}

.sidebar_item {
  max-width: 228px;
  width: 100%;
  height: 13px;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.disabled{
  background-color: $grey-color5;
  color:$black-color3;
  //opacity: 0.5;
  max-width: 228px;
  width: 100%;
  height: 13px;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.disabled:hover{
  color: $black-color3;
}

.sidebar_item_icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.sidebar_active_item {
  background-color: $red-color-1;
  p {
    color: $white-color;
  }
}

.category_label {
  margin: 12px 0 12px 0;
  color: $grey-color4;
  font-size: 16px;
}

.sidebar_notification {
  width: 6px;
  margin-left: 2px;
}


@media screen and (max-width:890px) {
  .mobile_nav{
    padding: 20px;
  }
  
}

.header{
  font-size: 20px;
  font-weight: bold;
}