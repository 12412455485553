.ant-collapse {
    min-width: 320px;
    background: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-right: 0px;
}
.ant-collapse-content-box {
    padding-left: 40px !important;
}
.ant-picker-calendar {
    width: 300px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    position: absolute;
    top: -60px;
    right: 0;
    .ant-picker-panel {
        display: none;
    }
  }