@import '../../style/variables.module.scss';

.icon{
    width: 18px;
    height: 18px;
}

.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px;
    border-radius: 16px;
    padding: 13px 16px 12px;
   // color: $grey-color9;
    font-size: 16px;
    box-sizing: border-box;
}

.container:hover{
    cursor: pointer;
}

.wrapper{
    display: flex;
    flex-direction: column;
}

.disabled{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px $grey-color11;
    border-radius: 16px;
    padding: 13px 16px 12px;
    color:$black-color3;
    font-size: 16px;
    box-sizing: border-box;
    background-color: $grey-color5;
}
