@import '../../style/variables.module.scss';

.container{
    width: 163px;
   // height: 108px;
   height: 90px;
    border-radius: 16px;
    border: solid 1px;
    padding: 15.4px 11px 9px 11.6px;
    box-sizing: border-box;
}

.icon{
    width: 21px;
    height: 21px;
}
.titlePart{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.iconPart{
    display: flex;
    align-items: center;
}
.platform{
    //color: $grey-color9;
    font-size: 12px;
    margin-left: 6.4px;
}

.totalPercentage{
    font-size: 13px;
    font-weight: 600;
}

.total{
    margin-top: 5px;
    font-size: 24px;
    line-height: 1.48;
    letter-spacing: -0.45px;
    font-weight: 600;
}

.previousPercentage{
    color: $green-color;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.arrow{
    width: 5.6px;
    height: 6.6px;
    margin-left: 6.2px;
}
.bottomPart{
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lastMonth{
    //color:$lightGrey-color;
    font-size: 11px;
    margin-left: 7px;
    font-weight: normal;
}