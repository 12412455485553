.main{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.header{
  display: flex;
  column-gap: 11px;
  span{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #293F62;
  }
  img{
    width: 20px;
    height: 20px;
    filter: brightness(0) saturate(100%) invert(23%) sepia(13%) saturate(2015%) hue-rotate(178deg) brightness(92%) contrast(93%);
  }
}

.cover{
  position: relative;
  cursor: pointer;
  &__image{
    border-radius: 22px;
    width: 296px;
    height: 264px;
    object-fit: cover;
    display: block;
  }
  &__selected{
    // box-sizing: border-box;
    // border: 5px #FF5D5F solid;
    // border-radius: 28px;
    position: relative;
    &__frame{
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      box-sizing: border-box;
      //background-color: #FF5D5F;
      border-radius: 22px;
      border: 5px #FF5D5F solid;
    }
  }
}

.overlay{
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  display: flex;
  bottom: 0;
  padding: 16px;
  column-gap: 12px;
  &__play{
    flex: 1;
    width: 65px;
    height: 65px;
  }
  &__container{
    flex: 4;
    display: flex;
    align-items: center;
    column-gap: 12px;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(36px);
    border-radius: 12px;
    padding: 12px;
    &__img{
      width: 24px;
      height: 24px;
      filter: brightness(100) saturate(100%);
    }
    &__data{
      display: flex;
      flex-direction: column;
      :first-child{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
      }
      :last-child{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
      }
    }
  }
}

.footer{
  display: flex;
  justify-content: space-between;
  &__field{
    display: flex;
    align-items: baseline;
    column-gap: 8px;
    :first-child{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #091D60;
    }
    :last-child{
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #EC1E24;
    }
  }
}