@import '../../style/variables.module.scss';

.container{
    // margin-top: 40px;
    padding-top: 10px;
}

.socialContainer{
    display: flex;
    align-items: center;
}

.socialContainer:first-child{
    margin-top: 0px;
}

.buttonContainer{
    margin-top: 40px;
    display: flex;
    max-width: 35%;
}

.buttonSpan{
    margin-right: 7px;
}

.platformsContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
}

.circleContainer{
    margin-left: 50px;
    margin-top: auto;
    position: relative;
}


.smallCircle{
    position:absolute;
    box-shadow: 0 30px 34px 0 rgba(133, 23, 25, 0.12);
    background-color: white;
    border-radius: 50%; 
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallIcon{
    width:25px;
}

.circleBase {
    border-radius: 50%; 
    border: 1px dashed $grey-color9;
    width: 228px;
    height: 228px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.innerCircle{
    border-radius: 50%; 
    border: 1px solid $grey-color14;
    width:120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.innerTitle{
    font-size: 24px;
}

.innerSubtitle{
    font-size: 12px;
    opacity: 0.7;
    margin-top: 5px;
}


.title{
    font-size: 15px;
    font-weight: bold;
    opacity: 0.46;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.audience, .money, .audience__money{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32%;
}

.buttonIcon{
    width: 18px;
    height: 18px;
}

.toggleText{
    display: flex;
    align-items: center;
    
}

.counterContainer{
    display: flex;
    margin-top: 40px;
    &__inputs{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}

@media screen and (max-width: 1163px) {
    .counterContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .circleBase{
       // margin-top: 20px;
    }
    .circleContainer{
        margin-top: 20px;
        width: 240px;
        height: 240px;
        margin-left: 0px;
    }
   
}

@media screen and (min-width: 720px) {
    .container{
        width: 600px;
    }
   
}

@media screen and (max-width: 500px) {
    .buttonContainer{
        max-width: 50%;
    }

    .toggleText{
        font-size: 13px !important;
    }
}

@media screen and (max-width: 400px) {
    .buttonContainer{
        max-width: 100%;
    }
}

.modalContainer{
    padding-top: 35px;
    display: flex;
    column-gap: 24px;
    row-gap: 24px;
    flex-wrap: wrap;
    &__img{
        width: 100%;
        img{
            height: 48px;
            width: 48px;
        }
    }
    &__header{
        margin-top: 30px;
        font-size: 20px;
        font-weight: 600;
    }
    &__bulletList{
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        &__line{
            display: flex;
            align-items: center;
            column-gap: 8px;
            img{
                width: 16px;
                height: 16px;
            }
        }
    }
    &__buttonWhite{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        padding: 18px 40px 18px 40px;
        background-color: white;
        width: fit-content;
        color: #EC1E24 !important;
        font-size: 15px;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        &__red{
            border: 1px solid red;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            padding: 18px 40px 18px 40px;
            background-color: white;
            width: fit-content;
            color: #EC1E24 !important;
            font-size: 15px;
            font-weight: 600;
            border-radius: 8px;
            cursor: pointer;
        }
    }
    
}
.panel{
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 300px;
    max-height: 550px;
    padding: 24px;
    padding-bottom: 0px;
    border-radius: 16px;
    border: 1px solid #8C92B2;
    position: relative;
    overflow: hidden;
    div{
        color: #091D60;
    }
    &__right{
        background-color: #EC1E24;
        border: 1px solid #EC1E24;
        div{
            color: white;
        }
    }
    &__background{
        // position: absolute;
        // left: 50%;
        // transform: translateX(-45%);
        // top: 42%;
        margin-left:auto;
        margin-right:auto;
        width: 80%;
        object-fit: cover;
        object-position: top;
        &__opacity{
            -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
        from(rgba(0,0,0,1)), to(rgba(0,0,0,0.5)));
        }
        &__mobile{
            position: absolute;
            left: 60%;
            width: 50%;
            -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
        from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
        }
    }
}

.selection{
    &__header{
        padding-top: 50px;
        display: flex;
        column-gap: 20px;
        &__option{
            padding: 0px 20px 10px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 5px solid #EC1E24;
            cursor: pointer;
            column-gap: 5px;
            img{
                width: 24px;
                height: 24px;
                display: block;
            }
            span{
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

.socialButton{
    display: flex;
    padding-left: 10px;
    align-items: center;
    :last-child{
        width: 12px;
        height: 12px;
        margin-left: auto;
    }
}
