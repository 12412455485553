@import "../../style/variables.module.scss";

.phoneButton:hover{
    div{
        background-color: rgba(227, 230, 236, 0.4) !important;
    }
}

.forgot{
    color: $red-color-1;
    margin-top: 7px;;
}

.phoneButton{
    div{
        background-color: transparent !important;
    }
}
.formLabels{
    font-size: 14px;
    color: $grey-textfield-color-2;
    margin-bottom: 8px;
    opacity:0.7;
    line-height: 17.64px;
}
.logoSection{
    display: flex;
    justify-content: center;
   
}
.logo{
    height: 40px;
}
.formSection{
    margin-bottom: 24px;
}
.welcome{
    color:$blue-color;
    //margin-top: 49px;
    margin-top: 50px;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -0.68px;
    display: flex;
    justify-content: center;
    img {
        width: 40px;
        height: 40px;
        margin-left: 16px;
    }
    margin-bottom: 13px;
}

.code{
    font-size: 16px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 10px;
    color: $blue-color;
}

.email{
    font-weight: 700;
    font-size: 20px;
    color: $blue-color;
}

.welcomeSubtext{
    color: $grey-textfield-color-2;
    //margin-top:6px;
    opacity:0.7;
    font-size: 16px;
    display: flex;
    margin: 0;
    justify-content: center;
    // width: 479px;
}

.codeSubtext{
    color: $grey-textfield-color-2;
    //margin-top:6px;
    opacity:0.7;
    font-size: 16px;
    margin-top:16px;
    margin-left: 0;
}


.googleContainer{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
}

.buttonContainer{
    margin-top: 41px;
}

.googleBtn {
   display:flex;
   align-items: center;
   justify-content: center;    
}
.googleLogo{
    width: 18px;
    margin-right: 8px;
}

.rememberContainer{
    display: flex;
    justify-content: space-between;
   align-items: center;
   color: $blue-color;
   margin-top: -8px;
}

.check{
    display: flex;
    align-items: center;
    color: $blue-color;
}
.checkBox{
    margin-top: 3px;
}

.checkSpan{
    margin-left: 8px;
    font-size: 14px;
    color:$blue-color;
    a{
        text-decoration: underline;
        font-weight: bold;
    }
}

.forgotLink {
    color: #f35d5d;
    font-size: 14px;
    &:hover {
        color: $blue-color;
    }
}
.didntSignUpContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 24px;
    color:$blue-color;
}

.didntSignUp{
    color:$pink-color-2;
    margin-left: 3px;
}
.didntSignUp:hover{
    cursor: pointer;
}

section.loginSection {
    display: flex;
    width: 100%;
    .loginLeft {
        width: 36%;
        background-image: url('../../assets/images/loginBkg.svg');
        background-repeat: repeat;
        background-size: cover;
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top:60px;
        .loginImg {
            img {
                width: 80%;
                margin: 0 auto;
                display: block;
            }
        }
        .loginContent {
           display: flex;
           flex-direction: column;
           align-items: center;
           text-align: center;
            color: $white-color;
            // margin-top: 6%;
            // padding-right:15%;
            // padding-left: 15%;
            padding-bottom: 134px;
            h4 {
                color: $white-color;
                font-size: 28px;
                font-weight: bold;
                line-height: 1.36;
                span {
                    display: block;
                }
            }
            p {
                margin-top: 23px;
                font-size: 14px;
                line-height: 1.57;
                opacity: 0.8;
                margin-left: 0;
                span {
                    display: block;
                }
            }
        }
    }
    .loginRight {
        width: 64%;
        min-height: 100vh;
        // margin-top: 118px;
        display: flex;
         align-items: center;
         justify-content: center;
        .loginRightInner {
            
            i.or {
                display: block;
                text-align: center;
                font-style: normal;
                margin-top: 5%;
                color: $grey-color4;
                position: relative;
                &:before, &:after {
                    content: "";
                    position: absolute;
                    top: 11px;
                    left: 0;
                    width: 46%;
                    height: 1px;
                    background:$grey-color6;
                }
                &:after {
                    left: auto;
                    right: 0;
                }
            }
            form {
                margin-top: 37px;
            }
           
        }
    }
    
}

@media screen and (min-width: 1580px) {
section.loginSection {
    .loginLeft {
        .loginContent {
            h4 {
                font-size: 34px;
            }
        }
    }
    .loginRight {
        .googleBtn {
            font-size: 16px;
            padding: 13px 0;
            img {
                top: -1px;
            }
        }
    }
   
}
}


@media screen and (min-width: 1850px) {
section.loginSection {
    .loginLeft {
        .loginContent {
            h4 {
                font-size: 40px;
            }
        }
    }
    .loginRight {
        .googleBtn {
            font-size: 18px;
            padding: 16px 0;
            img {
                top: -1px;
            }
        }
        .loginRightInner {
            width: 620px;
        }
    }
}
}

@media screen and (max-width: 1199px) {
section.loginSection {
    .loginLeft {
        .loginContent {
            h4 {
                font-size: 26px;
            }
        }
    }
    .loginRight {
        .googleBtn {
            font-size: 13px;
        }
    }
}
}

@media screen and (max-width: 991px) {
section.loginSection {
    .loginLeft {
        .loginContent {
            h4 {
                font-size: 22px;
            }
        }
    }
}
}

@media screen and (max-width: 767px) {
    .terms{
        font-size: 12px;
    }
    .rememberContainer{
        margin-top: 0px;
    }
    .didntSignUpContainer{
        margin-top: 20px;
        margin-bottom: 28px;
    }
    .welcome{
        font-size: 30px;
        img {
            width: 36px;
            height: 36px;
            margin-left: 8px;
        }
        margin-bottom: 13px;
        margin-top: 41px;
    }
    .welcomeSubtext{
        justify-content: center;
        width:100%;
        font-size: 14px;
    }
    form{
        margin-top: 36px;
    }
    .formLabels{
        font-size: 13px;
        margin-bottom: 14px;
    }
    .formSection{
        margin-bottom: 20px;
    }
section.loginSection {
    height: 100%;
    overflow: auto;
    .loginLeft {
        display: none;
    }
    .loginRight {
        width: 100%;
        height: 100%;
        // padding: 8% 0;
        padding: 41px 24px 77px 24px;
        overflow: auto;
        .loginRightInner {
            width: 100%;
            //padding: 0 6%;
            .googleBtn {
                img {
                    top: 0px;
                }
            }
        }
    }
    .socialsContainer{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
    }
}
}

.socialsContainer{
    display: flex;
    align-items: center;
    margin-top: 36px;
}

.termsContainer{
    display: flex;
    align-items: flex-start;
}

.terms{
   flex-wrap: wrap; 
   font-size: 13px;
   color: $blue-color;
}