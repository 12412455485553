@import '../../style/variables.module.scss';

.container{
    width:56px;
    height: 56px;
    color: $white-color;
    text-transform: uppercase;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

@media screen and (max-width: 767px) {
    .container{
        height: 42px;
        width: 42px;
    }
}