.main__wrapper {
    display: flex;
    align-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .header {
      width: 100%;
      height: 120px;
      background-color: #0948B3;
      display: flex;
      justify-content: center;
      .header__content {
        width: 75%;
        // display: flex;
        color: #ffffff;
        padding-top: 20px;
        p {
          margin-left: 0px;
          padding-top: 15px;
          padding-bottom: 15px;
          font-weight: 500;
        }
        h2 {
          color: white;
        }
      }
    }
    .content {
      display: flex;
      justify-content: center;
      width: 100%;
      .textColor {
        color: #92A4BE;
      }
      .content__wrapper {
        width: 75%;
        // padding: 40px 70px;
        background-color: #F4F9FD;
        display: flex;
        flex-direction: column;
        .privacy__policy {
          display: flex;
          justify-content: center;
          text-align: center;
        }
        .privacy__para {
          line-height: 1.5;
          padding: 10px 60px;
          font-size: medium;
        }
        .privacy__description {
          display: flex;
          padding: 10px 60px;
        }
        .privacy__description__point  {
          font-size: medium;
          display: flex;
          flex-direction: column;
          color: #92A4BE;
          .desc__points {
            ul {
              line-height: 1.6;
              li {
                padding-bottom: 10px;
                color: #92A4BE;
              }
            }
            padding: 0 100px;
          }
        }
      }
    }
    @media screen and (max-width: 550px) {
      .privacy__policy > h1 {
        font-size: 2rem;
      }
      .privacy__description {
        h3 {
          font-size: 1.5rem;
        }
        h5 {
          font-size: 12px;
          line-height: 1.2;
        }
        padding: 10px 10px !important;
      }
      .privacy__para {
        padding: 10px 10px !important;
      }
      .desc__points {
        padding: 0 30px !important;
      }
    }
  }