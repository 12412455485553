.header {
  width: 100%;
  height: 120px;
  background-color: #0948B3;
  display: flex;
  justify-content: center;
  .header__content {
    width: 75%;
    color: #ffffff;
    padding-top: 20px;
    p {
      margin-left: 0px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-weight: 500;
    }
    h2 {
      color: white;
    }
  }
}

section p {
  margin: 0;
  padding: 0;
}

.terms-and-conditions {
  line-height: 1.6;
  color: #000000;
  font-weight: 500;
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.terms-and-conditions h1,
.terms-and-conditions h2 {
  color: #0056b3;
  margin-top: 20px;
  font-size: 2rem;
}

.terms-and-conditions a {
  color: #0056b3;
  text-decoration: none;
}

.terms-and-conditions a:hover {
  text-decoration: underline;
}

.terms-and-conditions ul {
  list-style-type: disc;
  margin-left: 20px;
  padding-bottom: 20px;
}

.terms-and-conditions p {
  margin-bottom: 16px;
}

.terms-and-conditions strong {
  font-weight: bold;
}
