@import "./variables.module.scss";

html,
body {
  height: 100%;
  width: auto;
  line-height: 1;
  font-size: $font-size14;
  font-weight: 400;
  font-family: "EudoxusSans";
  //color: $black-color;
  margin: 0px;
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-button {
  background: $white-color;
  width: 0px;
  height: 0;
}
// body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px $black-color3;
//   background: $white-color;
// }
body::-webkit-scrollbar-thumb {
  // -webkit-box-shadow: inset 0 0 6px $black-color5;
  // background: $blue-color;
  height: 0px;
}
p,
ul,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
img {
  width: 100%;
}
h1 {
  font-size: $font-size48;
}
h2 {
  font-size: $font-size45;
}
h3 {
  font-size: $font-size32;
}
h4 {
  font-size: $font-size22;
}
h5 {
  font-size: $font-size20;
}
h6 {
  font-size: $font-size18;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
a:hover {
  color: $blue-color;
}
//285
.main {
  width: calc(100vw - 278px);
  @media only screen and (max-width: 890px) {
    width: 100vw;
  }
}

.onboarding {
  width: calc(100vw - 5px);
  background-repeat: repeat-y;
}