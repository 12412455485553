@import '../../style/variables.module.scss';

.wrapper{
    display: flex;
    overflow-y: auto;
    border-radius: 16px;
    max-height: calc(100vh - 100px);
    z-index:50;
    position:relative;
    box-sizing: border-box;
    
}
.container{
    width: 372px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    background-repeat: repeat-y;
    z-index: 21;
}

.divider{
    opacity: 0.4;
    width: 1px;
    background-color: $lightGrey-color-2;
}

.recent{
    color: $blue-color;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    margin-top: 39px;
}

.close{
    background-color: $white-color;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon{
        width: 10.6px;
        height: 10.6px;
    }
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: 49.7px;
}


@media screen and (max-width: 890px) {

    .wrapper{
        z-index: 21;
        position: absolute;
        right: 0px;
        top: 82px;
        width: 100%;
        border-radius: 0px;
        max-height: calc(100vh - 82px);
    }

    .container{
        width: 100%;
    }
    
}
