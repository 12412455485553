@import '../../style/variables.module.scss';

.container{
    padding: 24px 32px 88px 32px;
   // width: calc(100vw - 278px);
   width: calc(100vw - 310px);
    box-sizing: border-box;
}

.upperContainer{
    display: flex;
    margin-bottom: 32px;
}

.title{
    font-size: 22px;
    font-weight: bold;
}
.subtitle{
    font-size: 14px;
    margin-top: 6px;
    line-height: 1.5;
}

.phoneButton{
    div{
        background-color: transparent !important;
    }
}

.phoneButton:hover{
    div{
        background-color: rgba(227, 230, 236, 0.4) !important;
    }
}

.divider{
    background-color: $grey-color9;
    height: 1px;
    margin-top: 24px;
    opacity: 0.24;
}


.buttonContainer{
    display: flex;
    margin-top: 20px;
}

.formContainer{
    margin-top: 43px;
}

.textfieldLabel .lowerTextfieldLabel{
    font-size: 13px;
    color: $grey-textfield-color-2;
}

.lowerTextfieldLabel{
    width: 50%;
    margin-top: 10px;
    position: relative;
}

.checkboxContainer{
    display: flex;
    //justify-content: space-between;
    margin-top: 36px;
    font-size: 16px;
   //color: $blue-color;
}

.autocompletePlaceholder button[aria-expanded="false"] span:first-child span:nth-child(2), 
.autocompletePlaceholder button[aria-expanded="true"] span:first-child span:nth-child(2){
    color:$grey-color17 !important;
    font-size: 14px;
}

.darkAutocompletePlaceholder button[aria-expanded="false"] span:first-child span:nth-child(2), 
.darkAutocompletePlaceholder button[aria-expanded="true"] span:first-child span:nth-child(2){
    color: #8e8e8e !important;
    font-size: 14px;
}


.placeholder{
    width: 100%;
    //height: 100%;            
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0.7;
}

.flagsSelect button, .bankCountry button{
    font-size: 14px !important;
}

.darkTheme button{
    color: #fafafa;
}

.lightTheme button{
    color: $blue-color;
}

.flagsSelect, .bankCountry{
    border:0;
    margin-top: 8px;
    background-color: transparent;
    height: 56px;
    padding:0;
    font-size: 14px !important;
   
    ul {
        margin-top: 0;
        border-radius: 8px;
    }
    button{
        height:100%;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 13px 16px 12px 16px;

        span{
            margin-left: 0;
        }
        span:first-child{
            padding-left:0;
        }
    }
   
}

.upperRightButtonContainer{
    display: flex;
    justify-content: right;
    margin-top: 32px;
}

.lowerLeftButtonContainer{
    display: flex;
    justify-content: right;
    margin-top: 32px;
}

.close {
    margin-left:5px;
    border-radius: 2px;
    position: relative;
    padding-right: 10px;
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "\d7";
      font-size: 13px;
      text-align: center;
    }
  }

.option, .selectedOption{
    text-transform: capitalize;
    font-size: 16px;
    margin-right: 30px;
    margin-top: 41px;
    cursor: pointer;
}
.option:last-child{
    margin-right: 0;
}

.optionContainer{
    display: flex;
}
.selectedOption{
    color: $red-color-1;
    font-weight: bold;
    position:relative;
}

.selectedUnderline{
    height: 3px;
    background-color: $red-color-1;
    position: absolute;
    width: 100%;
    top: 39px;
}

.lowerSectionPart{
    display: flex;
    margin-top: 26px;
    width: 100%;
}

.bankCountry{
    width: 100%;
}

.checkboxes{
    margin-top: 2.5px;
    display: flex;
    //justify-content: space-between;
    font-size: 16px;
   // max-width: 420px;
    flex-wrap: wrap;
}
.checkbox{
    display: flex;
    margin-top: 21px;
}

.campaign{
    margin-left: 18px;
    margin-top: 3px;
    display: flex;
    align-items: center;
}

.i{
    width: 13px;
    height: 13px;
    margin-right: 15px;
    margin-left: 2px;
}

.info{
    position: relative;
    display: flex;
}

.infoText{
    visibility: hidden;
   
    min-width: 150px;
    text-align: left;
    padding: 7px;
    border-radius: 6px;
    font-size: 12px;
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
}
.info:hover .infoText {
    visibility: visible;
  }

.priceTitle{
    font-size: 17px;
    margin-top: 31.5px;
    font-weight: bold;
}

.priceSubtitle{
    opacity: 0.7;
    font-size: 14px;
    margin-top: 11px;
}

.priceRecommendations{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.currencyContainer{
    display: flex;
    align-items: center;
}

.currency{
    margin-top: 7px;
    margin-left: 5px;
    font-weight: bold;
}


@media screen and (max-width: 890px) {
.lowerTextfieldLabel{
    margin-top: 0px;
    width: 100%;
}
    .lowerSectionPart{
        flex-direction: column;
        margin-top: 23px;
    }
    .upperContainer{
        flex-direction: column;
        width: 100%;
    }

    .container{
        width: 100%;
        padding: 24px 16px 74px 16px;
    }
    .title{
        font-size: 20px;
    }
    .checkboxContainer{
        font-size: 14px;
    }


    .flagsSelect, .bankCountry{
        box-sizing: border-box;
        button{
            height:100%;
            box-sizing: border-box;
            padding: 20px;
        }
        span{
            font-size: 13px;
        }
        span:first-child{
            padding-left:0;
        }

        button::after{
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 1px 1px 3px 3px;
            margin-bottom: 3px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    
        button[aria-expanded="true"]::after{
            
            border-width: 0 1px 1px 0;
            display: inline-block;
            margin-top: 3px;
            padding: 1px 1px 3px 3px;
            transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
        }
    }
}

.connectedNetworkContainer{
    margin-top: 30px;
    display: grid;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
