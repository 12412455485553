@import '../../style/variables.module.scss';


.icon{
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
    &::after{
        pointer-events:none
    }
}

.container{
    //background-color: $white-color;
    position: absolute;
    top:60px;
    color: $grey-color9;
    font-size: 16px;
    z-index: 10;
   border: solid 1px;
    box-sizing: border-box;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.items{
    padding: 13px 16px 12px;
    border-bottom: solid 1px $grey-color13;
}
.items:last-child{
    border-bottom: 0;
}
.items:hover{
    cursor: pointer;
}

