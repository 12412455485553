@import '../../style/variables.module.scss';

.container{
    max-height: 732px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none;
    max-width: 1100px;
    margin: auto;
}
.header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    font-size: 16px;
    text-transform: uppercase;
    opacity: 0.6;
    font-weight: 600;
    text-align: start;
    :last-child{
        text-align: end;
        padding-right: 80px;
    }
    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
        :last-child{
            text-align: end;
            padding-right: 0px;
        }
    }
}

.container::-webkit-scrollbar { 
    display: none !important;
    width: 0 !important
  }

.date{
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.6;
    font-weight: 600;
    margin-bottom: 25px;
}
.wrapper{
    margin-top: 50px;
}
.wrapper:first-child{
    margin-top: 0;
}

.transactionContainer{
    // margin-top: 25px;
}
.separator{
    height: 1px;
    opacity: 0.4;
    background-color: $lightGrey-color-2;
}

.transaction{
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr 1fr 
    }
    align-items: center;
    margin-bottom: 26px;
    margin-top: 25px;
}

.transaction:last-child{
    margin-bottom: 0;
}

.icon{
    width: 42px;
    height: 42px;
}
.iconContainer{
    display:flex;
    flex-direction: row;
    align-items: center;
    width: 175px;
}

.typeContainer{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.platform{
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    white-space: nowrap;
}
.type{
    opacity: 0.7;
    font-size: 14px;
    text-transform: capitalize;
}

.transactionDate{
    font-size: 16px;
}
.amount{
    margin-right: 40px;
    font-size: 20px;
    font-weight: 600;
    min-height: 42px;
    display: flex;
    align-items: center;
}

.options{
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding-right: 30px;
    justify-self: end;
    row-gap: 10px;
}

.paid{
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #00dc4d;
    font-weight: 600;
    img{
        //filter: brightness(0) saturate(100%) invert(61%) sepia(99%) saturate(872%) hue-rotate(87deg) brightness(90%) contrast(108%);
        filter: hue-rotate(90deg) brightness(2);
        width: 22px;
        height: 22px;
        display: block;
        border-radius: 50%;
    }
}

.tooltip{
    background-color: white;
    padding: 24px;
    border: 1px solid lightgray;
    border-radius: 10px;
    //white-space: nowrap;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
    row-gap: 24px;
    &__row{
      cursor: pointer;
      display: flex;
      column-gap: 10px;
      align-items: center;
      justify-content: start;
      img{
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }

.earningIconWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
@media screen and (max-width: 700px) {
    .amount{
      min-height: 32px;
    }
}
  
  .modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    &__main{
      display: flex;
      column-gap: 20px;
    }
    &__icon{
      display: block;
      width: 40px;
      height: 40px;
    }
    &__spanIcon{
      vertical-align: middle;
      img{
        height: 16px;
        width: 16px;
      }
    }
    &__span{
      display: inline;
    }
    &__bolded{
      font-weight: 600;
      display: inline;
    }
    &__text{
      max-width: 415px;
      display: flex;
      flex-direction: column;
      row-gap: 35px;
    }
    &__buttons{
      display: flex;
      justify-content: start;
      column-gap: 16px;
      &__button{
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
        border: 1px solid lightgray;
        background: none;
        color: #293F62;
        cursor: pointer;
      }
    }
  }