@import "../../style/variables.module.scss";

.field{
    background-color: transparent !important;
    border:0;
    width:100%;
    font-size:15px;
    margin:0 !important;
    padding:0 !important;
}

.field::placeholder{
    font-size:15px;
   opacity: 0.5;
}

input:focus {
    outline:0;
}

.eye{
    
    background-color: transparent;
    outline: none;
    border: none;
}

.eye:hover{
    cursor: pointer;
}


.fieldContainer{
    min-height: 56px;
    padding: 15px 20px 15px 20px;
    border: 1px solid rgb(211, 214, 222);
    border-radius: 10px;
    box-sizing: border-box;
    display:flex;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .field::placeholder{
        font-size:14px;
    }
}
