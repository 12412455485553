@import '../../style/variables.module.scss';

.container{
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
}

.placeholderIcon{
    width:48px;
    height:48px;
    border-radius: 50%;
    background-color: $grey-color2;
    opacity: 0.6
}

.top{
    display: flex;
    &__icon{
        width: 48px;
        height: 48px;
        margin-right: 24px;
    }
    &__text{
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        font-size: 13px;
    }

    &__timestampcontainer{
        align-items: center;
        width:100%;
    }
    &__title{
        font-size: 16px;

    }
    &__type{
        color: $lightBlue-color3;
        text-transform: capitalize;
        margin-right: 35px;
    }

}

.middle{
    width: 200px;
    padding-left: 72px;
     color: $grey-color9;
     font-size: 13px;
     word-wrap: break-word;
     margin-top: 16px;
     display: flex;
     justify-content: space-between;

     &__text{
         width: 120px;
         line-height: 1.69;
     }
}
@media screen and (max-width: 890px) {

    .middle{
        width: calc(100vw - 80px);
        box-sizing: border-box;
        &__text{
            width: 100%;
            margin-right: 20px;
            box-sizing: border-box;
            
        }

        display: flex;
        justify-content: space-between;
    }

    .top{
        &__icon{
            width: 48px !important;
            height: 48px !important;
        }
    }
    
}
